import React, { useEffect, useState, useRef } from 'react';
import { Link, useParams } from 'react-router-dom';
import { FilterProvider, useFilter } from '../../context/FilterContext';
import { useGetBlog, useGetBlogs, useGetPropBlogReason, useLikeDislikeBlog, usePostBlogReport } from '../../services/blogService';
import LastBlogs from '../../components/blogAndNews/lastBlogs';
import crumbsArrow from "../../assets/images/crumbsArrows.svg"
import CustomTooltip from '../../components/Tooltip/tooltip';
import { useTranslation } from 'react-i18next';
import MenuDropdown from '../../components/PropertyComponents/MenuDropdownProps/MenuDropdownProps';
import ReportModal from '../../components/Modals/ReportModal/ReportModal';
import { ReportForm } from '../../helpers/types/general';
import { Grid, Modal, Spin, notification } from 'antd';
import './BlogDetailsPage.scss'
import Filters from '../../components/blogAndNews/Filters';
import ShareModal from '../../components/Modals/ShareModal/ShareModal';
import { useLanguage } from '../../context/LanguageContext';
import { convertObjectToImageString } from '../../helpers/helpers';
import { useAuthContext } from '../../auth/AuthProvider';
import Login from "../../pages/user/login/login";
import { BASE_URL, CURRENT_URL } from "../../helpers/types";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useNativeShare } from '../../components/Modals/ShareModal/nativeShare';

interface BlogDetails {
  id: string;
  title: string;
  content: string;
}
interface BlogDetailsPage {
  filters: any;
  setSelectedFilter: any;
  selectedFilter: any;
  handleFilterSelect: any
}
interface Blog {
  id: string;
  title: string;
  summary: string;
  category: string;
}

const BlogDetailsPage: React.FC = () => {
  const { filters, selectedFilter, handleFilterSelect, setSelectedFilter, sectionsResults } = useFilter();
  const { id } = useParams<{ id: string }>();
  const { result: blogDetails, loading, error, refetch: blogRefetch } = useGetBlog(id);
  const blogdataTitle = blogDetails?.result?.map((item: any) => (item?.title))
  const viewCount = blogDetails?.result?.map((item: any) => (item?.viewCount))
  const likeCount = blogDetails?.result?.map((item: any) => (item?.likeCount))
  const disLikeCount = blogDetails?.result?.map((item: any) => (item?.dislikeCount))
  const blogSectionName = blogDetails?.result?.map((item: any) => (item?.section.name))
  const blogTitle = blogDetails?.result?.map((item: any) => (item?.title))
  const whatsAppMsg = blogDetails?.result?.map((item: any) => (item?.whatsAppMsg))
  const [videoUrl, setVideoUrl] = useState<string | null>(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isLiked, setIsLiked] = useState<any>();
  const { result, refetch } = useGetBlogs({ page: 0, size: 3, section: id });
  const { language } = useLanguage();
  const { t } = useTranslation();
  const [isHeaderFixed, setIsHeaderFixed] = useState(false);
  const [lastScrollY, setLastScrollY] = useState(0);
  const [reportBoolean, setReportBoolean] = useState(false)
  const { reasons: reportReasons } = useGetPropBlogReason({ language: language.toUpperCase() });
  const [isReportModalVisible, setIsReportModalVisible] = useState(false);
  const [isShareModalVisible, setIsShareModalVisible] = useState(false);
  const [formReportRequest, setFormReportRequest] = useState<ReportForm>({
    Id: Number(id),
    catID: 1,
    text: ""
  });
  const { responseData, refetch: postReportBlog } = usePostBlogReport(formReportRequest, reportBoolean);
  const screens = Grid.useBreakpoint();
  const imageRef = useRef<HTMLImageElement | null>(null);
  const { result: likeDislikeBlog, refetch: refetchLike } = useLikeDislikeBlog();
  const [isUserModalVisible, setIsUserModalVisible] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    const userAgent = navigator.userAgent || navigator.vendor || (window as any).opera;
    if (/android/i.test(userAgent) || /iPhone|iPad|iPod/i.test(userAgent)) {
      setIsMobile(true);
    }
  }, []);
  const handleNativeShare = useNativeShare(result?.whatsAppMsg, result?.id);

  const showUserModal = () => setIsUserModalVisible(!isUserModalVisible);
  const { isAuthenticated, isLoading } = useAuthContext();
  const handleBlogClick = (id: string) => {
    window.location.href = `/blog/${id}`;
  };
  const handleReactionClick = async (flag: number) => {
    if (!isAuthenticated) {
      showUserModal()
    }
    else {
      let sendFlag = flag;
      if ((flag === 1 && isLiked === true) || (flag === 0 && isLiked === false)) {
        sendFlag = -1;
      } else if (flag === 1 && isLiked === false) {
        sendFlag = 1;
      } else if (flag === 0 && isLiked === true) {
        sendFlag = 0;
      }
      const params = {
        Id: id,
        flag: sendFlag,
      };

      try {
        await refetchLike({ data: params });
        await blogRefetch();
        if (sendFlag === -1) {
          //  message for -1 flag
        } else if (sendFlag === 1) {
          notification.success({
            message: t('BlogsAndNews.blogLiked'),
            placement: 'topRight',
          });
        } else if (sendFlag === 0) {
          notification.success({
            message: t('BlogsAndNews.blogDisLiked'),
            placement: 'topRight',
          });
        }
      } catch (error) {
        console.error('Error refetching like/dislike:', error);
      }
    }
  };
  const handleShareClick = (e: React.MouseEvent<HTMLImageElement>) => {
    e.stopPropagation()
    if (isMobile) {
      handleNativeShare()
    }
    else {
      setIsShareModalVisible(true);
    }
  };
  const handleCloseShareModal = () => {
    setIsShareModalVisible(false);
  };
  const handleReportClick = () => {
    setIsReportModalVisible(true);
  };
  const handleCloseReportModal = () => {
    setIsReportModalVisible(false);
  };
  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      const imageHeight = imageRef.current?.offsetHeight || 0;
      if (currentScrollY > lastScrollY && currentScrollY > imageHeight) {
        setIsHeaderFixed(true);
      } else if (currentScrollY < lastScrollY && currentScrollY < imageHeight) {
        setIsHeaderFixed(false);
      }
      setLastScrollY(currentScrollY);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [lastScrollY, screens.xs]);

  useEffect(() => {
    const isLikedBlog = blogDetails?.result?.map((item: any) => item.isLiked);
    isLikedBlog?.forEach((isLiked: boolean | null) => {
      setIsLiked(isLiked)
    });
  }, [blogDetails?.result]);
  const parseContentForYouTubeLinks = (content: string) => {
    const youtubeRegex = /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:watch\?v=|embed\/)|youtu\.be\/)([a-zA-Z0-9_-]{11})(?:[&?][^ ]*)?/g;

    return content.replace(youtubeRegex, (match, videoId) => {
      const thumbnailUrl = `https://img.youtube.com/vi/${videoId}/maxresdefault.jpg`;
      return `
 <a class='youtube-link' data-video-id='${videoId}' href='javascript:void(0);' 
   style='position: relative; display: block; width:80%; box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); border-radius: 16px;'>
  <img src='${thumbnailUrl}' alt='YouTube Video Thumbnail' 
       style='width: 100%; height: auto; border-radius: 16px; display: block;height:auto' />
  <img src='/images/apts/icons/youtubeModalSvg.svg' alt='YouTube Logo' 
     style='position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); pointer-events: none; width: 10%; height: auto;' />
</a>
      `;
    });
  };

  const handleThumbnailClick = (videoId: string) => {
    setVideoUrl(`https://www.youtube.com/embed/${videoId}`);
    setIsModalVisible(true);
  };

  const blogdataContent = blogDetails?.result?.map((item: any) => {
    const parsedContent = parseContentForYouTubeLinks(item.content);
    return parsedContent;
  });

  useEffect(() => {
    if (!blogdataContent) return;
    const contentElement = document.querySelector('.blogDetailsContent');

    const handleYouTubeLinkClick = (event: any) => {
      const linkElement = event.target.closest('.youtube-link');
      if (linkElement) {
        event.preventDefault();
        const videoId = linkElement.getAttribute('data-video-id');
        handleThumbnailClick(videoId);
      }
    };

    if (contentElement) {
      contentElement.addEventListener('click', handleYouTubeLinkClick);
    }

    return () => {
      if (contentElement) {
        contentElement.removeEventListener('click', handleYouTubeLinkClick);
      }
    };
  }, [blogdataContent]);

  const handleModalClose = () => {
    setIsModalVisible(false);
    setVideoUrl(null);
  };

  useEffect(() => {
    const lockScroll = () => {
      const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;
      document.body.style.paddingRight = `${scrollbarWidth}px`;
      document.body.style.overflow = 'hidden';
    };
    const unlockScroll = () => {
      document.body.style.overflow = '';
      document.body.style.paddingRight = '';
    };

    if (isUserModalVisible || isModalVisible) {
      lockScroll();
    } else {
      unlockScroll();
    }
    return () => {
      unlockScroll();
    };
  }, [isUserModalVisible, isModalVisible]);

  const formatViewCount = (count: any) => {
    if (count >= 1000000) {
      return (count / 1000000).toFixed(1) + "M";
    } else if (count >= 1000) {
      return (count / 1000).toFixed(1) + "K";
    } else {
      return count;
    }
  };

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <meta property="og:title" content={blogTitle?.[0] ?? 'Blog'} />
          <meta
            property="og:description"
            content={blogDetails?.result?.map((item: any) => item.content).join(' ').slice(0, 150) || 'Blog Description'}
          />
          <meta
            property="og:image"
            content={convertObjectToImageString(blogDetails?.result?.[0]?.blogImageCover) || 'defaultImageUrl'}
          />
          <meta property="og:url" content={`${CURRENT_URL}/blog/${id}`} />
          <meta property="og:type" content="article" />
          <meta name='description' content='React Helmet.' />

          <title>{blogTitle?.[0] ?? 'housez blogs'}</title>
          <link rel="canonical" href={`${CURRENT_URL}/blog/${id}`} />
        </Helmet>
      </HelmetProvider>

      <FilterProvider>
        <div className='BlogDetailsPage'>
          <div className='sticky-filter-buttons'>
            <Filters
              filters={sectionsResults?.result}
              selectedFilter={selectedFilter}
              onFilterSelect={handleFilterSelect}
              setSelectedFilter={setSelectedFilter}
              blogSectionName={blogSectionName}

            />
          </div>
          {!blogDetails?.result?.length ? (
            <Spin
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minHeight: '200px',
              }}
            />
          ) :
            (
              <div>
                <img className='BlogImage'
                  ref={imageRef}
                  src={convertObjectToImageString(blogDetails?.result[0]?.blogImageCover)}
                  alt="Background"
                  style={{
                    height:"100%",
                    width:"100%"
                  }}
                />
                <div className='BlogDetailsNav'
                  style={{
                    backgroundColor: isHeaderFixed ? "#fff" : 'rgba(255, 255, 255, 0.7)',
                    marginTop: screens.xs ? '-54px' : "-77px",
                    height: screens.xs ? "50px" : "75px",
                    paddingRight: screens.xs ? '22px' : "24px"
                  }}
                >
                  <div className='views'>
                    <img
                      className='blogDetailsNavIcon'
                      src={'/images/apts/icons/eye-icon-blogs.svg'}
                      alt="Eye-icon"
                    />
                    <span className='viewsNumber'>{formatViewCount(viewCount)} {t("BlogsAndNews.views")}</span>
                  </div>

                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    {/* <div
                      style={{
                        display: 'flex',
                        gap: "15px"
                      }}
                    >
                      <div
                        style={{ display: 'flex', alignItems: 'center', gap: '5px', cursor: 'pointer' }}
                        onClick={() => { handleReactionClick(1) }}
                      >
                        <span className='likedNum'>{likeCount}</span>
                        <img
                          className='blogDetailsNavIcon'
                          src={isLiked ? '/images/apts/icons/likefilled.svg' : '/images/apts/icons/likeOutline.svg'}
                        />
                      </div>
                      <div className='LikesLine' />
                      <div
                        style={{ display: 'flex', alignItems: 'center', gap: '5px', cursor: 'pointer' }}
                        onClick={() => { handleReactionClick(0) }}
                      >
                        <span className='disLikedNum'>{disLikeCount}</span>
                        <img
                          className='blogDetailsNavIcon'
                          src={isLiked === false ? '/images/apts/icons/dislikeFilled.svg' : '/images/apts/icons/dislikeOutline.svg'}
                        />

                      </div>
                    </div> */}
                    <div
                      style={{
                        paddingLeft: screens.xs ? "23px" : "64px",
                        paddingRight: screens.xs ? "24px" : "36px",
                      }}
                    >
                      <CustomTooltip title={t("common.Share")}>
                        <img
                          style={{ cursor: "pointer", width: screens.xs ? "16px" : "unset" }}
                          className="lf-btn"
                          src={'/images/apts/icons/blogsShare.svg'}
                          onClick={handleShareClick}
                          alt="Share"
                        />
                      </CustomTooltip>
                    </div>
                    <div>
                      <MenuDropdown
                        onReportClick={handleReportClick}
                      />
                    </div>
                  </div>
                </div>
                <div className="breadCrumbs" style={{ padding: "0px 6%" }}>
                  <Link to='/blogs' className="homeCrumbs"
                    onClick={() => {
                      setSelectedFilter('all');
                      sessionStorage.setItem('selectedFilter', 'all');
                      sessionStorage.removeItem('selectedFilterName');
                    }}
                  >{t("BlogsAndNews.Home")}
                  </Link>
                  <img className='Brackets' src={crumbsArrow} alt="Arrow" />
                  <Link to='/blogs' className="selectedFilter"
                    onClick={() => {
                      setSelectedFilter(blogSectionName);
                      sessionStorage.setItem('selectedFilterName', blogSectionName);

                    }}
                  >{blogSectionName}</Link>
                  {blogTitle && (
                    <>
                      <img className='Brackets' src={crumbsArrow} alt="Arrow" />
                      <p className="selectedFilterTitle">{blogTitle}</p>
                    </>
                  )
                  }
                </div>
                <div style={{ padding: '0px 6%', marginTop: '20px' }}>
                  <div style={{ cursor: 'pointer' }}>
                    <h1 className='blogDetailsTitle'>{blogdataTitle}</h1>
                    <div className='blogDetailsContent' dangerouslySetInnerHTML={{ __html: blogdataContent }} />

                  </div>
                </div>
                <LastBlogs
                  onBlogClick={handleBlogClick}
                  selectedFilter={selectedFilter}
                  setSelectedFilter={setSelectedFilter}
                />
              </div>
            )
          }
          <ReportModal
            reportReasons={reportReasons?.result || []}
            formReportRequest={formReportRequest}
            setFormReportRequest={setFormReportRequest}
            isVisible={isReportModalVisible}
            onClose={handleCloseReportModal}
            title={t('property.ReportTitleBlog')}
            description={t('property.ReportDescriptionBlog')}
            setReportBoolean={setReportBoolean}
          />
        </div>
      </FilterProvider>
      {isUserModalVisible && (
        <Modal
          getContainer={false}
          open={isUserModalVisible}
          onCancel={showUserModal}
          footer={null}
          centered
          loading={isLoading}
          modalRender={(modal) => (
            <div className="modalCard" >
              {modal}
            </div>
          )}
          style={{ padding: "0px" }}
        >
          <Login />
        </Modal>
      )}

      <ShareModal isOpen={isShareModalVisible} onClose={handleCloseShareModal} message={whatsAppMsg} />
      <Modal
        // title="Watch Video"
        open={isModalVisible}
        onCancel={handleModalClose}
        footer={null}
        width={800}
        className='YouTubeModal'
        closeIcon={false}
        centered
        getContainer={false}
        destroyOnClose={true}
      >
        {videoUrl && (
          <iframe
            width="100%"
            height="400"
            src={videoUrl}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="YouTube Video"
          ></iframe>
        )}
      </Modal>
    </>
  );
};

export default BlogDetailsPage;
