import { AgentDTO } from "./types/agent";

export const BASE_URL = process.env.REACT_APP_BASE_URL || '';
export const CURRENT_URL = process.env.REACT_APP_URL || '';



// Companies

// Coming Soon

export interface NotifyMeResponse {
  result: AgentDTO[];
  errorMessage: string | null;
}