import React, { useEffect, useState } from 'react';
import { Card, Grid, Modal, notification } from 'antd';
import CustomTooltip from '../Tooltip/tooltip';
import { useTranslation } from 'react-i18next';
import './BlogCard.scss';
import eyeIcon from "../../assets/images/eye-icon.svg";
import { addBlogToFavorite, deleteBlogToFavorite } from '../../services/blogService';
import ShareModal from '../Modals/ShareModal/ShareModal';
import { convertObjectToImageString } from '../../helpers/helpers';
import { useAuthContext } from '../../auth/AuthProvider';
import Login from "../../pages/user/login/login";
import { Image } from '../../helpers/types/general';
import { Helmet } from 'react-helmet';
import { BASE_URL } from '../../helpers/types';
import { useNativeShare } from '../Modals/ShareModal/nativeShare';

interface BlogCardProps {
  blog: {
    author?: string;
    id: string;
    title: string;
    summary: string;
    category: string;
    viewCount?: number;
    section?: { name?: string };
    content?: string;
    whatsAppMsg?: string;
    isFavorite?: boolean;
    blogImageCover?: Image;
  };
  onClick: () => void;
  selectedFilter?: string;
}

const BlogCard: React.FC<BlogCardProps> = ({ blog, onClick }) => {
  const screens = Grid.useBreakpoint();
  const { t } = useTranslation();
  const [isShareModalVisible, setIsShareModalVisible] = useState(false);
  const [isUserModalVisible, setIsUserModalVisible] = useState(false);
  const { isAuthenticated, isLoading } = useAuthContext();

  const toggleUserModal = () => setIsUserModalVisible((prev) => !prev);
  const toggleShareModal = () => setIsShareModalVisible((prev) => !prev);
  const handleNativeShare = useNativeShare(blog.whatsAppMsg, blog.id);

  const handleShareClick = (e: React.MouseEvent<HTMLImageElement>) => {
    e.stopPropagation()
    if (isMobile) {
      handleNativeShare();
    } else {
      toggleShareModal();
    }
  };
  const handleFavoriteToggle = async (e: React.MouseEvent) => {
    e.stopPropagation();

    if (!isAuthenticated) {
      toggleUserModal();
      return;
    }

    try {
      const params = { Id: blog.id, flag: blog.isFavorite ? 0 : 1 };
      if (blog.isFavorite) {
        await deleteBlogToFavorite().refetch({ data: params });
        notification.success({ message: t('BlogsAndNews.BlogRemovedFromFav') });
      } else {
        await addBlogToFavorite().refetch({ data: params });
        notification.success({ message: t('BlogsAndNews.BlogAddedToFav') });
      }
    } catch (error) {
      notification.error({ message: t('BlogsAndNews.ErrorTogglingFav') });
      console.error('Error toggling favorite:', error);
    }
  };
  const formatViewCount = (count: any) => {
    if (count >= 1000000) {
      return (count / 1000000).toFixed(1) + "M";
    } else if (count >= 1000) {
      return (count / 1000).toFixed(1) + "K";
    } else {
      return count;
    }
  };
  useEffect(() => {
    document.body.style.overflow = isUserModalVisible || isShareModalVisible ? 'hidden' : '';
    return () => { document.body.style.overflow = ''; };
  }, [isUserModalVisible, isShareModalVisible]);
  const contentWithoutImages = (blog.content || blog.summary).replace(/<img\s+[^>]*src="[^"]*"[^>]*>/gi, '');
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const userAgent = navigator.userAgent || navigator.vendor || (window as any).opera;
    if (/android/i.test(userAgent) || /iPhone|iPad|iPod/i.test(userAgent)) {
      setIsMobile(true);
    }
  }, []);
  
  
  return (
    <>
      <Helmet>
        <meta property="og:title" content={blog.title ?? 'Blog'} />
        <meta property="og:description" content={(blog.content || blog.summary)?.slice(0, 150) || 'Blog Description'} />
        <meta property="og:image" content={convertObjectToImageString(blog.blogImageCover)} />
        <meta property="og:url" content={`${BASE_URL}/blog/${blog.id}`} />
        <meta property="og:type" content="article" />
      </Helmet>
      <Card
      onClick={onClick}
        className='blogCard'
        bodyStyle={{ padding: screens.xs ? '3px 16px 9px 21px' : '9px 22px 12px 22px' }}
        cover={<img className='blogImg' alt={blog.title} src={convertObjectToImageString(blog.blogImageCover)} />}
      >
        <div className="love">
          <CustomTooltip title={t("common.Share")}>
            <img className="lf-btn" src={'/images/apts/icons/share.svg'} onClick={handleShareClick} alt="Share" />
          </CustomTooltip>
          {/* <CustomTooltip title={blog.isFavorite ? t("common.RemovefromFavorites") : t("common.AddtoFavorites")}>
            <img
              className={`lf-btn ${blog.isFavorite ? 'filled' : 'empty'}`}
              src={blog.isFavorite ? '/images/apts/icons/love-filled.svg' : '/images/apts/icons/love.svg'}
              onClick={handleFavoriteToggle}
              alt="Favorite"
            />
          </CustomTooltip> */}
        </div>

        <div>
          <div className='blogViews'>
            <p className='viewTitle'>{blog.section?.name || blog.category || blog.author}</p>
            <div style={{ display: 'flex', alignItems: "center", gap: "5px" }}>
              <img className='viewTitle' src={eyeIcon} alt="Views" />
              <p className='viewTitle'>
                {formatViewCount(blog.viewCount)} {t("BlogsAndNews.views")}
              </p>
            </div>
          </div>
          <span className="blog-title">{blog.title}</span>
          <p onClick={onClick} className='read-more'>{t("BlogsAndNews.READMORE")}</p>
          <div className="blog-description" dangerouslySetInnerHTML={{ __html: contentWithoutImages || blog.summary }} />
        </div>
      </Card>

      {isUserModalVisible && (
        <Modal open={isUserModalVisible} onCancel={toggleUserModal} footer={null} centered>
          <Login />
        </Modal>
      )}


      <ShareModal isOpen={isShareModalVisible} onClose={toggleShareModal} message={blog.whatsAppMsg} id={blog.id} />
    </>
  );
};

export default BlogCard;
