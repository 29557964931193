import React, { useEffect, useRef, useState } from 'react';
import { Row, Col, Grid, Spin } from 'antd';
import BlogCard from './BlogCard';
import './Filter.scss';
import crumbsArrow from "../../assets/images/crumbsArrows.svg";
import { useTranslation } from 'react-i18next';

interface Blog {
  id: string;
  title: string;
  summary: string;
  category: string;
}

interface BlogListProps {
  blogs: Blog[];
  onBlogClick: (id: string) => void;
  selectedFilter: string;
  isRTL?: boolean;
  setSelectedFilter?: any;
  selectedFilterName?: any;
  loading?: any
}

const BlogList: React.FC<BlogListProps> = ({
  setSelectedFilter,
  blogs,
  onBlogClick,
  selectedFilter,
  isRTL,
  selectedFilterName,
  loading
}) => {
  const { t, i18n } = useTranslation()
  const dir = i18n.dir()
  const [visibleBlogs, setVisibleBlogs] = useState<number>(selectedFilter === 'all' ? 4 : 2);
  const [activePage, setActivePage] = useState<number>(0);
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const screens = Grid.useBreakpoint();
  useEffect(() => {
    setVisibleBlogs(selectedFilter === 'all' ? 4 : 2);
  }, [selectedFilter]);

  const handleSeeMore = () => {
    setVisibleBlogs((prevVisibleBlogs) => prevVisibleBlogs + 5);
  };

  const handleIndicatorClick = (index: number) => {
    if (scrollContainerRef.current && !isRTL) {
      const containerWidth = scrollContainerRef.current.clientWidth;
      const scrollPosition = dir === 'rtl' ? index * -containerWidth : index * containerWidth;
      scrollContainerRef.current.scrollTo({
        left: isRTL ? -scrollPosition : scrollPosition,
        behavior: 'smooth',
      });
      setActivePage(index);
      console.log(activePage)
    }
  };

  const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
    const { scrollLeft, clientWidth, scrollWidth } = e.currentTarget;
    let activeIndex;

    if (isRTL) {
      const scrollRight = scrollWidth - (scrollLeft + clientWidth);
      activeIndex = Math.round(scrollRight / clientWidth);
    } else {
      activeIndex = Math.round(scrollLeft / clientWidth);
    }
    const sanitizedIndex = String(activeIndex).replace('-', '');
    setActivePage(Number(sanitizedIndex));
    console.log(activePage)

  };
  return (
    <div className="blogs-filters">
      {loading ? (
        <Spin
          className='spin'
        />
      ) : (
        <>
          {selectedFilter !== 'all' && (
            <div className="breadCrumbs">
              <p
                className="homeCrumbs"
                onClick={() => {
                  setSelectedFilter('all');
                  sessionStorage.removeItem('selectedFilter');
                  sessionStorage.removeItem('selectedFilterName');
                }}
              >
                {t('BlogsAndNews.Home')}
              </p>
              <img
                className="Brackets"
                src={crumbsArrow}
                style={{ transform: dir === 'rtl' ? 'scaleX(-1)' : 'unset' }}
              />
              <p className="selectedFilter">{selectedFilterName || selectedFilter}</p>
            </div>
          )}

          <p className="title">{t('BlogsAndNews.Trending')}</p>

          {blogs?.length === 0 ? (
            <div
            className='noBlog' >
              <img style={{maxWidth:"100%"}} src='/images/apts/icons/noBlog.svg'/>
             <p className='NoBlogText'>{t('BlogsAndNews.NoData')}</p>
            </div>
          ) : (
            <>
              {screens.xs ? (
                <>
                  <div
                    ref={scrollContainerRef}
                    className="horizontal-scroll"
                    style={{
                      display: 'flex',
                      overflowX: 'auto',
                      scrollSnapType: 'x mandatory',
                    }}
                    onScroll={handleScroll}
                  >
                    {blogs?.slice(0, blogs.length).map((blog) => (
                      <div
                        key={blog.id}
                        style={{
                          minWidth: 'calc(100%)',
                          scrollSnapAlign: 'start',
                          margin: '10px',
                          paddingLeft: '1px',
                          paddingRight: '1px',
                        }}
                      >
                        <BlogCard selectedFilter={selectedFilter} blog={blog} onClick={() => onBlogClick(blog.id)} />
                      </div>
                    ))}
                  </div>

                  <div className="indicators" style={{ textAlign: 'center', marginTop: '10px' }}>
                    {Array.from({ length: blogs.length }).map((_, index) => (
                      <span
                        key={index}
                        onClick={() => handleIndicatorClick(index)}
                        style={{
                          display: 'inline-block',
                          width: activePage === index ? '30px' : '10px',
                          height: '10px',
                          margin: '0 5px',
                          borderRadius: activePage === index ? '20px' : '50%',
                          backgroundColor: activePage === index ? 'var(--primary-color)' : '#ccc',
                          transition: 'all 0.5s',
                          marginBottom: screens.xs ? '23px' : '42px',
                          marginTop: '15px',
                        }}
                      />
                    ))}
                  </div>
                </>
              ) : (
                <Row gutter={[16, 16]} style={{ paddingBottom: screens.xs ? '23px' : '42px' }}>
                  {blogs?.slice(0, visibleBlogs).map((blog, index) => (
                    <Col key={blog.id} xs={24} sm={12} md={12} lg={index % 4 === 0 || index % 4 === 3 ? 16 : 8}>
                      <BlogCard selectedFilter={selectedFilter} blog={blog} onClick={() => onBlogClick(blog.id)} />
                    </Col>
                  ))}
                </Row>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default BlogList;
