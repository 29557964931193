import React, { useEffect, useState } from 'react';
import {
    MailOutlined,
    UserOutlined,
    EyeInvisibleOutlined,
    EyeFilled
} from '@ant-design/icons';
import './login.scss';
import { useAuthContext } from '../../../auth/AuthProvider';
import { Input } from 'antd';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../../auth/useAuth';
import { AxiosError } from 'axios';


interface LoginProps {
    isLoading?: boolean;
}
const Login: React.FC<LoginProps> = ({ isLoading }) => {

    const { t } = useTranslation()
    const [isSignUp, setIsSignUp] = useState(false);
    const [isForgotPassword, setIsForgotPassword] = useState(false);
    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [firstName, setFirstName] = useState<string>('');
    const [lastName, setLastName] = useState<string>('');
    const [rememberMe, setRememberMe] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
    const [message, setMessage] = useState<string | null>(null);

    const { login, signup, loginWithGoogle, loginWithApple, forgotPassword } = useAuthContext();




    const handleEmailLogin = async (e: React.FormEvent) => {
        e.preventDefault();
        // console.log("isLoading", isLoading);

        setError(null);
        try {
            const isEmailVerified = await login(email, password);
            if (!isEmailVerified) {
                setError('Please verify your email before logging in.');
            } else {
                console.log('Login successful');
            }
        } catch (err) {
            if (err instanceof Error) {
                let error: Error = err; // Now TypeScript knows 'err' is an Error
                setError(error?.message);

                console.error(error); // Use the error variable
            } else {
                setError('An unknown error occurred');

                console.error("An unknown error occurred");
            }
        }
    };

    const handleEmailSignUp = async (e: React.FormEvent) => {
        e.preventDefault();
        setError(null);
        try {
            await signup(email, password, firstName, lastName);
            console.log('Signup successful');
            setIsSignUp(false); // Switch to login form after successful signup
        } catch (err) {
            if (err instanceof Error) {
                setError(err.message); // Set the error message state
            } else if (typeof err === 'object' && err !== null) {
                // If err is an object, check for the description property
                if ('description' in err) {
                    const description = (err as any).description; // Use type assertion to access description
                    if (typeof description === 'string') {
                        setError(description);
                    } else if (typeof description === 'object' && description.rules) {
                        const messages = description.rules.map((rule: { message: string }) => rule.message);
                        setError(messages.join(', '));
                    }
                } else {
                    setError('An unknown error occurred');
                }
            } else {
                setError('An unknown error occurred');
            }
        }
    };

    const handleForgotPassword = async (e: React.FormEvent) => {
        e.preventDefault();
        setError(null);
        setMessage(null);
        try {
            const result = await forgotPassword(email);
            setMessage(result);
            setIsSignUp(false);
            setIsForgotPassword(false);
        } catch (err) {
            setError(err instanceof Error ? err.message : 'Failed to send password reset email');
        }
    };


    return (
        <div className="login-container">
            <div className="login-box">
                {isSignUp ? (
                    <>
                        <h2 className='signInText'>{t("SignUp.SignUp")}</h2>
                        <p className='EnterEmail'>{t("SignUp.Createaccount")}</p>
                        {error && <p className="error">{error}</p>}
                        <form onSubmit={handleEmailSignUp}>
                            <div className="input-group">
                                <span> {t("SignUp.FirstName")} </span>

                                <Input
                                    type="text"
                                    placeholder={t("SignUp.EnterFirstName")}
                                    className='InputBoxes'
                                    value={firstName}
                                    onChange={(e) => setFirstName(e.target.value)}
                                    required
                                    prefix={<UserOutlined className="icon" />}
                                />
                            </div>
                            <div className="input-group">
                                <span > {t("SignUp.LastName")} </span>

                                <Input
                                    type="text"
                                    className='InputBoxes'
                                    placeholder={t("SignUp.EnterLastName")}
                                    value={lastName}
                                    onChange={(e) => setLastName(e.target.value)}
                                    required
                                    prefix={<UserOutlined className="icon" />}

                                />
                            </div>
                            <div className="input-group">
                                <span > {t("SignUp.email")} </span>

                                <Input
                                    type="email"
                                    className='InputBoxes'
                                    placeholder="yourname@gmail.com"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    required
                                    prefix={<img src='/images/apts/icons/email_svg.svg' />}
                                />
                            </div>
                            <div className="input-group">
                                <span > {t("SignUp.password")} </span>

                                <Input.Password
                                    type="password"
                                    className='InputBoxes'
                                    placeholder={t("SignUp.Enterpassword")}
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    required
                                    prefix={<img src='/images/apts/icons/lock_svg.svg' />}
                                    iconRender={(visible) => (visible ? <EyeFilled /> : <EyeInvisibleOutlined />)}

                                />
                            </div>
                            <div className="actions">
                                <div
                                    className='rememberGroup'
                                >
                                    <input
                                        type="checkbox"
                                        checked={rememberMe}
                                        onChange={() => {
                                            setRememberMe(!rememberMe);
                                        }}
                                    />
                                    <span className='rememberMe'> {t("SignIn.rememberMe")}</span>
                                </div>
                            </div>
                            <button type="submit" className="login-button" >
                                {t("SignUp.Register")}
                                <img className='arrowSignIn' src='/images/apts/icons/signIn-Arrow.svg' />

                            </button>
                        </form>
                        <div className="divider">
                            <span className='or-word'> {t("SignIn.or")} </span>
                        </div>
                        <div className='social-media-group'>
                            <button className="social-button google-button" onClick={loginWithGoogle}>
                                <img src='/images/apts/icons/google.svg' className="social-icon" />
                                {t("SignIn.SignGoogle")}
                            </button>
                            <button className="social-button apple-button" onClick={loginWithApple}>
                                <img src='/images/apts/icons/apple-logo.svg' className="social-icon" />
                                {t("SignIn.SignApple")}
                            </button>
                        </div>
                        <p className="footer-text">
                            {t("SignUp.Alreadyhaveaccount")} <a onClick={() => { setIsSignUp(false); setError(null) }}> {t("SignUp.SignIn")} </a>
                        </p>
                    </>
                ) : isForgotPassword ? (
                    <>
                        <h2 className='signInText'>{t("SignIn.Forgot")}</h2>
                        <p className='EnterEmailForgetPass'>
                            {t("forgotPassword.Enteremailaddress")}</p>
                        {error && <p className="error">{error}</p>}
                        {message && <p className="message">{message}</p>}
                        <form onSubmit={handleForgotPassword}>
                            <div className="input-group">
                                <span > {t("SignIn.email")} </span>

                                <Input
                                    className='InputBoxes'

                                    type="email"
                                    placeholder="yourname@gmail.com"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    required
                                    prefix={<MailOutlined className="icon" />}
                                />
                            </div>
                            <button type="submit" className="login-button">
                                {t("forgotPassword.Continue")}
                                <img className='arrowSignIn' src='/images/apts/icons/signIn-Arrow.svg' />

                            </button>
                        </form>
                        <p className="footer-text">
                            {t("forgotPassword.RememberPassword")} <a onClick={() => { setIsForgotPassword(false); setError(null) }}> {t("SignUp.SignIn")} </a>
                        </p>
                    </>
                ) : (
                    <>
                        <h2 className='signInText'>{t("SignIn.SignintoyourAccount")}</h2>
                        <p className='EnterEmail'>{t("SignIn.Enteremailandpassword")}</p>
                        {error && <p className="error">{error}</p>}
                        {message && <p className="message">{message}</p>}
                        <form onSubmit={handleEmailLogin}>
                            <div className="input-group">
                                <span > {t("SignIn.email")} </span>

                                <Input
                                    className='InputBoxes'
                                    id="email"
                                    type="email"
                                    placeholder="yourname@gmail.com"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    required
                                    prefix={<img src='/images/apts/icons/email_svg.svg' />}


                                />

                            </div>

                            <div className="input-group">
                                <span > {t("SignIn.password")}</span>

                                <Input.Password
                                    className='InputBoxes'

                                    type="password"
                                    placeholder={t("SignUp.Enterpassword")}
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    required
                                    prefix={<img src='/images/apts/icons/lock_svg.svg' />}
                                    iconRender={(visible) => (visible ? <EyeFilled /> : <EyeInvisibleOutlined />)}

                                />
                            </div>
                            <div className="actions">
                                <div
                                    className='rememberGroup'
                                >
                                    <input
                                        type="checkbox"
                                        checked={rememberMe}
                                        onChange={() => {
                                            localStorage.setItem('rememberMe', JSON.stringify(!rememberMe));

                                            setRememberMe(!rememberMe);
                                        }}
                                    />
                                    <span className='rememberMe'> {t("SignIn.rememberMe")}</span>
                                </div>


                                <a onClick={() => { setIsForgotPassword(true); setError(null) }} className="forgot-password">
                                    {t("SignIn.Forgot")}
                                </a>
                            </div>
                            <button type="submit" className="login-button" disabled={isLoading}>
                                {t("SignIn.signIn")}

                                <img className='arrowSignIn' src='/images/apts/icons/signIn-Arrow.svg' />


                            </button>
                        </form>
                        <div className="divider">
                            <span className='or-word'> {t("SignIn.or")} </span>
                        </div>
                        <div className='social-media-group'>
                            {/* <p> {t("SignIn.dontHave")} </p> */}

                            <button className="social-button google-button" onClick={loginWithGoogle}>
                                <img src='/images/apts/icons/google.svg' className="social-icon" />
                                {t("SignIn.SignGoogle")}
                            </button>
                            <button className="social-button apple-button" onClick={loginWithApple}>
                                <img src='/images/apts/icons/apple-logo.svg' className="social-icon" />
                                {t("SignIn.SignApple")}
                            </button>
                        </div>

                        <p className="footer-text">
                            {t("SignIn.dontHave")} <a onClick={() => { setIsSignUp(true); setError(null) }}> {t("SignUp.SignUp")} </a>
                        </p>

                    </>
                )}
            </div>
        </div>
    );
};

export default Login;
