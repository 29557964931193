import authConfig from './authConfig';
import { LoginProvider } from './useAuth';


export interface User {
    id: string;
    email: string;
    emailVerified: boolean;
    name: string;
    firstName: string;
    lastName: string;
    picture: string;
    loginProvider: LoginProvider;
}

export interface AuthService {
    login: (email: string, password: string) => Promise<User>;
    signup: (
        email: string,
        password: string,
        firstName: string,
        lastName: string
    ) => Promise<void>;
    forgotPassword: (email: string) => Promise<string>;
    loginWithGoogle: () => void;
    logout: () => void;
    getUser: () => User | null;
    getUserProfile: () => Promise<any>;
    getToken: () => Promise<any>;
}

export const authService: AuthService = {
    async login(email: string, password: string) {
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}/account/login`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', channel: "w" },
            body: JSON.stringify({ email, password }),
            credentials: 'include',
        });
        if (!response.ok) {
            const errorData = await response.json();
            const errorMessage = errorData.errorMessage || errorData.description || 'Login failed';

            throw new Error(errorMessage);
        }

        const res = await response.json();
        const { accessToken, accessTokenExpiry: tokenExpires, refreshToken } = res?.result?.[0];

        const userInfoResponse = await fetch(
            `https://${authConfig.domain}/userinfo`,
            {
                headers: { Authorization: `Bearer ${accessToken}` },
            }
        );

        if (!userInfoResponse.ok) {
            throw new Error('Failed to fetch user information');
        }

        const userInfo = await userInfoResponse.json();

        if (!userInfo.email_verified) {
            throw new Error('Email not verified. Please verify your email.');
        }

        const user: User = {
            id: userInfo.sub,
            email: userInfo.email,
            emailVerified: userInfo.email_verified,
            name: userInfo.name || '',
            firstName: userInfo.given_name || '',
            lastName: userInfo.family_name || '',
            picture: userInfo.picture,
            loginProvider: LoginProvider.USERNAME_PASSWORD,
        };

        // Store tokens and user information in localStorage
        localStorage.setItem('access_token', accessToken);
        localStorage.setItem('token_expires', tokenExpires);
        localStorage.setItem('refreshToken', refreshToken);
        localStorage.setItem('user', JSON.stringify(user));

        return user;
    },

    async signup(
        email: string,
        password: string,
        firstName: string,
        lastName: string
    ) {
        const response = await fetch(
            `https://${authConfig.domain}/dbconnections/signup`,
            {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    client_id: authConfig.clientId,
                    email,
                    password,
                    connection: 'Username-Password-Authentication',
                    given_name: firstName,
                    family_name: lastName,
                }),
            }
        );

        if (!response.ok) {


            const errorData = await response.json();
            console.log("errorData", errorData);
            // Create a detailed error message
            const errorMessage = errorData.message || errorData.description || 'Signup failed';
            throw new Error(errorMessage);
        }
    },

    async forgotPassword(email: string) {
        try {
            const response = await fetch(
                `https://${authConfig.domain}/dbconnections/change_password`,
                {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({
                        client_id: authConfig.clientId,
                        email: email,
                        connection: 'Username-Password-Authentication',
                    }),
                }
            );

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.description || 'Failed to send reset email');
            }

            return 'Password reset email sent successfully. Please check your inbox.';
        } catch (error) {
            // console.error('Error in forgotPassword:', error);
            throw new Error('An unexpected error occurred');
        }
    },


    loginWithGoogle() {
        window.location.href = `https://${authConfig.domain}/authorize?connection=google-oauth2&client_id=${authConfig.clientId}&response_type=token&redirect_uri=${window.location.origin}`;
    },

    logout() {
        localStorage.removeItem('access_token');
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('token_expires');
        localStorage.removeItem('user');
        localStorage.setItem('rememberMe', 'false');

        console.log('User logged out successfully');
    },

    getUser() {
        const user = localStorage.getItem('user');
        return user ? JSON.parse(user) : null;
    },

    getUserProfile: async () => {
        const token = localStorage.getItem('access_token');
        if (!token) {
            throw new Error('User is not authenticated');
        }

        const response = await fetch(`${process.env.REACT_APP_BASE_URL}/account/userProfile`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

        if (!response.ok) {
            throw new Error('Failed to fetch user profile');
        }

        return response.json();
    },

    getToken: async () => {
        const token = localStorage.getItem('access_token');
        if (!token) {
            throw new Error('User is not authenticated');
        }

        return token;
    },
};
