import axios from "axios";

export const postNotifyMe = async (email: string) => {
  const BASE_URL =
    Number(process.env.BASE_URL) || "https://api.housez.ae/housezApi";

  try {
    const response = await axios.post(`${BASE_URL}/notifyMe/notifyMe`, { "name": email },
      {
        headers: {
          channel: 'W'
        }
      });
    return { data: response.data, error: null };
  } catch (error) {
    console.error("Error posting email:", error);
    return { data: null, error: error || "An error occurred" };
  }
};
