  import React, { useState } from "react";
  import './partners.scss';
  import HeaderPages from "../../components/pagesFooterHeaderSection/header";
  import { useTranslation } from "react-i18next";
  import { Col, Row, Button, Grid, } from "antd";
  import PartnerModal from "./partnerFormModal";

  export default function Partners() {
    const { t } = useTranslation();
    const { useBreakpoint } = Grid;
    const [isModalVisible, setIsModalVisible] = useState(false);

    const partners = [
      {
        name: "INNOVATION EXPERTS",
        type: t("Partenrs.StrategicPartner"),
        description:t("Partenrs.partner1description"),
        image: "/images/apts/icons/partnerTest.svg",
      },
      {
        name: "Holo",
        type: t("Partenrs.MortgageSolutionsPartner"),
        description:t("Partenrs.partner2description"),
        image: "/images/apts/icons/holoPartner.svg",
      },
    ];

    const [expandedIndex, setExpandedIndex] = useState(null);
    const screens = useBreakpoint();

    const toggleDescription = (index: any) => {
      setExpandedIndex(expandedIndex === index ? null : index);
    };

    const showModal = () => {
      setIsModalVisible(true);
    };

    const handleCancel = () => {
      setIsModalVisible(false);
    };

    return (
      <>
        <HeaderPages h1={t("Partenrs.Partenrs")} />
        <div className="partners-section">
          <div className="partners-body-section">
            {partners.map((partner, index) => {
              const isExpanded = expandedIndex === index;
              const maxCharsForPreview = 120;
              const shouldShowMore = partner.description.length > maxCharsForPreview;
              const descriptionPreview = shouldShowMore
                ? partner.description.slice(0, maxCharsForPreview) + "..."
                : partner.description;

              return (
                <div key={index} className="partners-card">
                  <Row gutter={[21, 0]} justify="start">
                    <Col xs={8} sm={6} md={5} lg={4} >
                      <div className="partner-image">
                        <img src={partner.image} alt={partner.name} />
                      </div>
                    </Col>
                    <Col xs={16} sm={18} md={19} lg={19} >
                      <div className="partner-Card-Desc">
                        <p className="partner-name">{partner.name}</p>
                        <p className="partner-type">{partner.type}</p>
                        <p className="partner-desc">
                          {screens.md ? partner.description : isExpanded ? partner.description : descriptionPreview}
                        </p>
                        {!screens.md && shouldShowMore && (
                          <Button
                            type="link"
                            onClick={() => toggleDescription(index)}
                            className="show-more-btn"
                          >
                            {isExpanded ? t("Partenrs.ReadLess") : t("Partenrs.ReadMore")}
                          </Button>
                        )}
                      </div>
                    </Col>
                  </Row>
                </div>
              );
            })}
          </div>
        </div>

        <div className="contact-us-home-section">
          <div className="text-container">
            <div className="section-content">
              <p className="partnerWith"> {t("Partenrs.Partnerwith")} </p>
              <p className="fillOutText">{t('Partenrs.fillout')}</p>
            </div>
            <Button type="primary" className="request-button" onClick={showModal}>
              {t("Partenrs.FillTheForm")}
            </Button>
          </div>
        </div>
        {isModalVisible && (
          <PartnerModal isModalVisible={isModalVisible} handleCancel={handleCancel} />
        )}


      </>
    );
  }
