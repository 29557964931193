// src/App.tsx
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import NavBar from './components/navBar/navBar';
import Home from './pages/home/home';
import Footer from './components/footer/footer';
import BlogDetailsPage from './pages/blogsAndNews/BlogDetailsPage';
import BlogsPage from './pages/blogsAndNews/BlogsPage';
import PrivateRoute from './auth/PrivateRoute';
import AboutUs from './components/aboutUsPage/aboutusPage';
import Career from './components/carrer/carrer';
import PrivacyPolicy from './pages/privacyPolicy/privacyPolicy';
import TermsAndConditions from './pages/TermsAndCondition/TermsAndConditions';
import Explore from './pages/explore/explore';
import Companies from './pages/companies/companies';
import { LayoutWrapper } from './components/layoutWrapper/layoutWrapper';
import ComingSoon from './pages/landingPage/landingPage';
import AiIcon from './components/HousezAi/aiIcon';
import ContactUsPage from './pages/contactUs/contactUs';
import { FilterProvider } from './context/FilterContext';
import Agents from './pages/agents/agents';
import AgentProfile from './pages/agentProfile/agentProfile';
import CompanyProfile from './pages/companyProfile/companyProfile';
import Property from './pages/Property/property';
import JoinNow from './pages/joinNow/joinNow';
import Partners from './pages/partners/partners';

function App() {
  return (

    <Router>
      {/* <NavBar /> */}
      <div className="root_element">
        {/* <AiIcon /> */}
        {/* <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/about-us' element={<AboutUs />} />
          <Route path='/contact-us' element={<ContactUsPage />} />
          <Route path='/privacy-policy' element={<PrivacyPolicy />} />
          <Route path='/terms' element={<TermsAndConditions />} />
          <Route path='/career' element={<Career />} />
          <Route path='/properties' element={<Explore />} />
          <Route path='/favorite' element={<Favorite />} />
          <Route
            path="/favorite"
            element={<PrivateRoute component={Favorite} />}
          />

          <Route path='/companies' element={<Companies />} />
          <Route path="/blog/:id" element={<FilterProvider><BlogDetailsPage /></FilterProvider>} />
          <Route path="/blogs" element={<FilterProvider><BlogsPage /></FilterProvider>} />
          <Route path='/agents' element={<Agents />} />
          <Route path='/props/:area' element={<Explore />} />
          <Route path='/agentProfile/:id' element={<AgentProfile />} />
          <Route path='/companyProfile/:id' element={<CompanyProfile />} />
          <Route path='/property/:id' element={<Property />} />
          <Route path='/join-us' element={<JoinNow />} />
          <Route path='/partners' element={<Partners />} />
        </Routes> */}

        {/* </LayoutWrapper> */}
      </div>
      <Routes>
        <Route path="/blog/:id" element={<FilterProvider><BlogDetailsPage /></FilterProvider>} />
        <Route path="/blogs" element={<FilterProvider><BlogsPage /></FilterProvider>} />
        <Route path='/privacy-policy' element={<PrivacyPolicy />} />
        <Route path='/' element={<ComingSoon />} />
        <Route path='/about-us' element={<AboutUs />} />
        <Route path='/contact-us' element={<ContactUsPage />} />
        <Route path='/terms' element={<TermsAndConditions />} />
        <Route path='/partners' element={<Partners />} />
      </Routes>
      {/* <Footer/> */}
    </Router>

  );
}

export default App;
