import { useLanguage } from "../context/LanguageContext";
import { FileUplodeStatusRequest, partnerRequest, PartnerWithUsResponse } from "../helpers/types/partner";
import { CreateAxiosPostRequest } from "./GeneralService";

export const usePartnerWithUs = (params?: partnerRequest) => {
    const { language } = useLanguage();
    const { responseData, loading, error, refetch } = CreateAxiosPostRequest<PartnerWithUsResponse>({
        url: `partner/partnerWithUs`,
        data: params,
        headers: {
            language: language.toUpperCase(),
            section: "H",
            channel: "w",

        },
        enabled: false,

    });
    // Extract the result and errorMessage from the responseData
    const result = responseData?.result || [];
    const errorMessage = responseData?.errorMessage;
    return { result, errorMessage, loading, error, refetch };
};


export const useFileUplodeStatus = (params?: FileUplodeStatusRequest) => {
    const { language } = useLanguage();
    const { responseData, loading, error, refetch } = CreateAxiosPostRequest<PartnerWithUsResponse>({
        url: `partner/fileUplodeStatus`,
        data: params,
        headers: {
            language: language.toUpperCase(),
            section: "H",
            channel: "w",

        },
        enabled: false,

    });
    // Extract the result and errorMessage from the responseData
    const result = responseData?.result || [];
    const errorMessage = responseData?.errorMessage;
    return { result, errorMessage, loading, error, refetch };
};