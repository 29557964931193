import useAxios from "axios-hooks";
import axios from 'axios';
import { BaseParam } from "../helpers/types/general";
import { BASE_URL } from "../helpers/types";
import { createQueryParams } from "../helpers/helpers";
import Cookies from 'js-cookie';
interface AxiosGetRequestConfig<T = any> {
  url?: string;
  params?: Partial<BaseParam & Record<string, any>>;
  headers?: {
    language?: string;
    channel?: string | "W" | "MA" | "MI"; // Channel (W - Website, MA - Android Mobile, MI - iOS Mobile)
    section?: string | "H";
    authorization?: string; // Bearer token
    USER_ID?: string
  };
  enabled?: boolean;
}

interface AxiosPostRequestConfig<T = any> {
  url?: string;
  data?: Partial<BaseParam & Record<string, any>>;
  headers?: {
    language?: string;
    channel?: string;
    section?: string;
    authorization?: string; // Bearer token
  };
  enabled?: boolean;
}

const isAccessTokenExpired = (accessTokenExpiry: string) =>
  !!accessTokenExpiry && new Date() > new Date(accessTokenExpiry);

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    console.log("axios_error", error);

    const originalRequest = error.config;
    const token = localStorage.getItem('access_token');
    const token_expires = localStorage.getItem('token_expires');
    const rememberMe = localStorage.getItem('rememberMe');
    const isNeedRefreshToken = token && isAccessTokenExpired(token_expires!) && rememberMe == 'true';
    const isNeedlogut = token && isAccessTokenExpired(token_expires!) && rememberMe == 'false';

    if (error.response && error.response.status === 401 && !originalRequest._retry && isNeedRefreshToken) {
      originalRequest._retry = true;

      const resp = await refreshAccessToken();
      console.log("resp", resp);


      const access_token = resp;

      localStorage.Item('access_token', access_token!);
      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${access_token}`;
      return axios(originalRequest);
    } else if (isNeedlogut) {
      localStorage.removeItem('access_token');
      localStorage.removeItem('refreshToken');
      localStorage.removeItem('token_expires');
      localStorage.removeItem('user');
      localStorage.setItem('rememberMe', 'false');
      window.location.reload();
    }
    return Promise.reject(error);
  }
);

const refreshAccessToken = async (): Promise<string | null> => {
  let headers = { USER_ID: "1", language: "en", channel: "w", section: "" }
  const requestHeaders: Record<string, string | undefined> = {
    "Content-Type": "application/json",
    Language: headers.language,
    Channel: headers.channel,
    Section: headers.section,
  };
  const ref = localStorage.getItem('refreshToken');
  console.log("ref", ref);

  if (!ref) {
    console.error('No refresh token available');
    return null;
  }
  try {
    const response = await axios.post(`${BASE_URL}/account/refreshToken`, { refreshToken: ref }, { headers: requestHeaders, withCredentials: true });
    const { accessToken } = response?.data?.result[0];

    localStorage.setItem('access_token', response?.data?.result[0]?.accessToken);
    localStorage.setItem('token_expires', response?.data?.result[0]?.accessTokenExpiry);
    localStorage.setItem('refreshToken', response?.data?.result[0]?.refreshToken);


    return accessToken;
  } catch (error) {
    console.error('Token refresh error:', error);
    return null;
  }
};



export const CreateAxiosGetRequest = <T = any>({
  url,
  params = {},
  headers = { USER_ID: "1", language: "en", channel: "w", section: "" },
  enabled = true,
}: AxiosGetRequestConfig<T>) => {
  const queryString = createQueryParams(params);
  const requestHeaders: Record<string, string | undefined> = {
    "Content-Type": "application/json",
    Language: headers.language,
    Channel: headers.channel,
    Section: headers.section,
  };
  const token = localStorage.getItem('access_token');
  const token_expires = localStorage.getItem('token_expires');
  const rememberMe = localStorage.getItem('rememberMe');
  const isNeedRefreshToken = token && isAccessTokenExpired(token_expires!) && rememberMe;
  if (headers.authorization && headers.authorization.startsWith("Bearer ") && headers.authorization.trim().length > 7) {
    requestHeaders.Authorization = `Bearer ${token}`!;
  }
  const [{ data, loading, error, response }, refetch] = useAxios<T>(
    {
      url: `${BASE_URL}/${url}?${queryString}`,
      method: "GET",
      headers: requestHeaders,
    },
    {
      manual: !enabled,
      useCache: false,

    }
  );
  return { data, loading, error, refetch, response };
};

export const CreateAxiosPostRequest = <T = any>({
  url,
  data = {},
  headers = { language: "en", channel: "w", section: "" },
  enabled = true,
}: AxiosPostRequestConfig<T>) => {

  const requestHeaders: Record<string, string | undefined> = {
    "Content-Type": "application/json",
    Language: headers.language,
    Channel: headers.channel,
    Section: headers.section,
  };
  if (headers.authorization && headers.authorization.startsWith("Bearer ") && headers.authorization.trim().length > 7) {
    requestHeaders.Authorization = headers.authorization;
  }

  const [{ data: responseData, loading, error, response }, refetch] =
    useAxios<T>(
      {
        url: `${BASE_URL}/${url}`,
        method: "POST",
        data,
        headers: requestHeaders,

      },
      { manual: !enabled, useCache: true }
    );



  if (error && error?.response) {

    if (error?.response?.status === 401) {
      localStorage.removeItem('access_token');
      localStorage.removeItem('user');
      window.location.reload()
    }

  }

  return { responseData, loading, error, refetch, response };
};
