import { Modal, Button, Form, Input, Select, Row, Col, notification } from "antd";
import './ReportModal.scss';
import { useLanguage } from "../../../context/LanguageContext";
import { useTranslation } from "react-i18next";
import { ReportCategory, ReportForm } from "../../../helpers/types/general";
import { useEffect } from "react";

interface ReportModalProps {
    reportReasons?: ReportCategory[] | undefined;
    formReportRequest?: ReportForm;
    setFormReportRequest: (values: any) => void;
    isVisible: boolean;
    onClose: () => void;
    title: string;
    description: string;
    setReportBoolean?: any
}

const ReportModal: React.FC<ReportModalProps> = ({
    reportReasons,
    formReportRequest,
    setFormReportRequest,
    isVisible,
    onClose,
    title,
    description,
    setReportBoolean
}) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const { Option } = Select;

    const handleSubmit = () => {

        form.validateFields().then(values => {
            setReportBoolean(true)
            setFormReportRequest({ ...formReportRequest, ...values });
            form.resetFields();
            onClose();
            notification.success({
                message: t('ReportModal.RequestSent'),
                description: t('ReportModal.RequestSuccessMessage'),
                placement: 'topRight'
            });
        }).catch(info => {
            console.error("Validate Failed:", info);

            notification.error({
                message: t('ReportModal.SubmissionError'),
                description: t('ReportModal.SubmissionErrorMessage'),
                placement: 'topRight'
            });
        });
    };

    const handleCancel = () => {
        form.resetFields();
        onClose();
    };

    useEffect(() => {
        const lockScroll = () => {
            const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;
            document.body.style.paddingRight = `${scrollbarWidth}px`;
            document.body.style.overflow = 'hidden';
        };

        const unlockScroll = () => {
            document.body.style.overflow = '';
            document.body.style.paddingRight = '';
        };

        if (isVisible) {
            lockScroll();
        } else {
            unlockScroll();
        }
        return () => {
            unlockScroll();
        };
    }, [isVisible]);
    return (
        <Modal
            getContainer={false}
            open={isVisible}
            title={title}
            onCancel={handleCancel}
            footer={null}
            centered
            className="report-modal"
        >
            <p className="modal-description">
                {description}
            </p>
            <Form form={form} layout="vertical">
                <label className="field-label">{t("ReportModal.selectReason", "Select reason")}</label>
                <Form.Item
                    name="catID"
                    rules={[{ required: true, message: t("ReportModal.selectReasonError", "Please select a reason") }]}
                >
                    <Select
                        placeholder={
                            <div className="icon-text-container">
                                <img src="/images/report/reason.svg" alt="reason" className="select-icon" />
                                <span>{t("ReportModal.selectReasonPlaceholder", "Select reason")}</span>
                            </div>
                        }
                    >
                        {reportReasons?.map(reason => (
                            <Option key={reason.id} value={reason.id}>
                                {reason.name}
                            </Option>
                        ))}
                    </Select>
                </Form.Item>

                <label className="field-label">{t("ReportModal.descriptionLabel", "Description")}</label>
                <Form.Item
                    name="text"
                    rules={[
                        { required: true, message: t("ReportModal.descriptionRequired", "Please enter a description") },
                        { max: 500, message: t("ReportModal.descriptionMaxLength", "Description cannot exceed 500 characters") }
                    ]}
                >
                    <Input.TextArea
                        placeholder={t("ReportModal.descriptionPlaceholder", "Type your message here...")}
                    />
                </Form.Item>

                <div className="submit-btn-container">
                    <Button type="primary" onClick={handleSubmit} block className="submit-btn">
                        {t("ReportModal.submit", "Send Report")}
                    </Button>
                </div>
            </Form>
        </Modal>
    );
};

export default ReportModal;
