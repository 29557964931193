import React, { useEffect, useState } from "react";
import { Col, Row, Button, Modal, Form, Input, Upload, UploadFile, notification } from "antd";
import './partnerModal.scss'
import { useTranslation } from "react-i18next";
import PhoneNumberInput from "../../components/phoneNumber/phoneNumber";
import { useFileUplodeStatus, usePartnerWithUs } from "../../services/partnerServices";
import { partnerRequest } from "../../helpers/types/partner";
import axios from "axios";
export default function PartnerModal({ isModalVisible, handleCancel }: { isModalVisible: boolean, handleCancel: () => void }) {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [textArea, setTextArea] = useState<string>("");
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const { result: partnerWithUsRes, refetch: refetchPartnerWithUs, loading } = usePartnerWithUs();
  const { result: uploadFileRes, refetch: refetchUploadFile, loading: loadingUploadFile } = useFileUplodeStatus();


  const handleSubmit = async (values: partnerRequest) => {
    console.log("Form values before submission:", values);

    let valuesRes: partnerRequest = {
      ...values,
      phoneNumber: phoneNumber,
      msg: textArea
    };

    try {
      const response = await refetchPartnerWithUs({ data: valuesRes });

      const partnerWithUsRes = response.data?.result;

      if (fileList.length > 0 && partnerWithUsRes && partnerWithUsRes.length > 0) {
        console.log("partnerWithUsRes", partnerWithUsRes);

        // Upload the file to S3
        await uploadFileToS3(partnerWithUsRes[0].url);

        // Update the file upload status
        await refetchUploadFile({

          data: {
            id: partnerWithUsRes[0] && partnerWithUsRes[0].Id,
            status: true
          }
        });
      } else {
        console.error("File or upload URL is missing");
      }
      notification.success({
        message: t('Partenrs.submitFormSuccess'),
        placement: 'topRight'
      });

    } catch (error) {
      console.error('Error PartnerWithUs:', error);
    }

    console.log("Form submitted:", valuesRes);
  };
  useEffect(() => {
    const lockScroll = () => {
      const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;
      document.body.style.paddingRight = `${scrollbarWidth}px`;
      document.body.style.overflow = 'hidden';
    };

    const unlockScroll = () => {
      document.body.style.overflow = '';
      document.body.style.paddingRight = '';
    };

    if (isModalVisible) {
      lockScroll();
    } else {
      unlockScroll();
    }
    return () => {
      unlockScroll();
    };
  }, [isModalVisible]);

  const handlePhoneChange = (value: string) => {
    setPhoneNumber(value);
  };
  const handleTextAreaChange = (value: string) => {
    form.setFieldsValue({ msg: value })
    setTextArea(value);
  };
  const normFile = (e: any) => {

    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };
  const handleChangeFile = (info: { fileList: UploadFile[] }) => {
    // Update the file list, ensuring we only keep one file
    setFileList(info.fileList.slice(-1)); // Keep only the last file
    console.log("fileList", fileList);

  };

  const uploadFileToS3 = async (url: string) => {
    if (!fileList.length) {
      console.error('No file to upload');
      return;
    }

    const file = fileList[0].originFileObj || fileList[0];

    if (!(file instanceof File)) {
      console.error('The file to upload is not a valid File object');
      return;
    }

    try {
      const response = await axios.put(url, file, {
        headers: {
          'Content-Type': file.type,
        }
      });

      if (response.status !== 200) {
        throw new Error('Failed to upload file to S3');
      }
      notification.success({
        message: t('Partenrs.uploadFileSuccess'),
        placement: 'topRight'
      });
    } catch (error) {
      notification.error({
        message: t('Partenrs.uploadFileFaild'),
        placement: 'topRight'
      });
    }
  };
  return (
    <Modal
      getContainer={false}
      open={isModalVisible}
      onCancel={handleCancel}
      footer={null}
      className="partner-modal"
      centered
      width="1212px"
      confirmLoading
    >
      <p className="partner-modal-header"> {t("Partenrs.Partnerwith")} </p>
      <Form form={form} layout="vertical" onFinish={handleSubmit}>
        <Row gutter={102}>
          <Col sm={12} xs={24} className="input-group">
            <span> {t("Partenrs.CompanyName")} </span>
            <Form.Item name="companyName"
              rules={[{ required: true, message: t('Partenrs.companyNameRrequired') }, { type: 'string', min: 3, message: t('Partenrs.companyNamelength') }]}><Input className='InputBoxes' /></Form.Item>

          </Col>
          <Col sm={12} xs={24} className="input-group">
            <span>{t("Partenrs.ContactPerson")}</span>
            <Form.Item name="contactPerson"
              rules={[{ required: true, message: t('Partenrs.contactPersonRequired') },
              { type: 'string', min: 3, message: t('Partenrs.contactPersonlength') }
              ]}><Input className='InputBoxes' /></Form.Item>


          </Col>
        </Row>
        <Row gutter={102}>
          <Col sm={12} xs={24} className="input-group">
            <span>{t("Partenrs.EmailAddress")}</span>

            <Form.Item name="email" rules={[{ required: true, message: t('Partenrs.emailRequired') },
            { type: 'email', message: t("newsLetter.invalidEmail") }]}><Input className='InputBoxes' type="email" /></Form.Item>

          </Col>
          <Col sm={12} xs={24} className="input-group">
            <span>{t("Partenrs.PhoneNumber")}</span>
            <Form.Item name="phoneNumber" rules={[{ required: true, message: t('Partenrs.phoneNumberRequired') }]}><PhoneNumberInput value={phoneNumber} onChange={handlePhoneChange} /></Form.Item>


          </Col>
        </Row>
        <Row gutter={102}>
          <Col sm={12} xs={24} className="input-group">
            <span>{t("Partenrs.Website")}</span>
            <Form.Item name="websiteUrl"
              rules={[{ required: true, message: t('Partenrs.websiteUrlRequired') },
              { type: 'url', required: true, message: t('Partenrs.websiteUrlInvalid') },
              { type: 'string', min: 6, message: t('Partenrs.websiteUrllength') }]}><Input className="InputBoxes" /></Form.Item>


          </Col>

          <Col sm={12} xs={24} className="input-group">
            <Row gutter={4}>
              <Col sm={15} xs={14}>
                <span>{t("Partenrs.CompanyProfile")}</span>
                <Input className="InputBoxes" disabled value={fileList[0] && fileList[0].name} />
              </Col>

              <Col sm={9} xs={10}>
                <Form.Item
                  valuePropName="fileList"
                  getValueFromEvent={normFile}

                >
                  <Upload beforeUpload={() => false} action={""} className="upload" listType="text" maxCount={1} onChange={handleChangeFile} >
                    <Button className="upload-btn" block>{t("Partenrs.Upload")}</Button>
                  </Upload>
                </Form.Item>
              </Col>
            </Row>
          </Col>

          {/* <Col sm={12} xs={24} className="input-group">
            <Row gutter={4}>


              <Form.Item
                name="upload"
                valuePropName="fileList"
                getValueFromEvent={normFile}
              >
                <Upload className="upload" name="logo" action="/upload.do" listType="text" >
                  <Button className="upload-btn" block>{t("Partenrs.Upload")}</Button>
                </Upload>
              </Form.Item>
            </Row>
          </Col> */}
        </Row>

        <Col span={24} className="input-group">
          <span>{t("Partenrs.Message")}</span>
          <Form.Item name="msg" rules={[{ required: true, message: t('Partenrs.msgRequired') }, { type: 'string', min: 24, message: t('Partenrs.msglength') }]} initialValue={textArea}> <Input.TextArea rows={4} className='InputBoxesMessage' onChange={(event) => handleTextAreaChange(event.target.value)} /></Form.Item>


        </Col>
        <Button className="partner-button" type="primary" htmlType="submit" block loading={loading} disabled={loading}>
          {t("Partenrs.OnlineRegistration")}
        </Button>
      </Form >
    </Modal >
  );
}
