import React, { useState, useEffect } from 'react';
import BlogCard from './BlogCard';
import './Filter.scss';
import { Col, Grid, Row, Spin } from 'antd';
import { useGetBlogs } from '../../services/blogService';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n';

interface Blog {
    id: string;
    title: string;
    summary: string;
    category: string;
}
interface BlogListProps {
    onBlogClick: (id: string) => void;
    selectedFilter: any;
    setSelectedFilter: any;
    selectedFilterName?: any;
}

const LastBlogs: React.FC<BlogListProps> = ({ onBlogClick, selectedFilter }) => {

    const { t } = useTranslation();
    const [blogs, setBlogs] = useState<Blog[]>([]);
    const [page, setPage] = useState<number>(0);
    const { id } = useParams<{ id: string }>();
    const screens = Grid.useBreakpoint();

    const { result, loading, error } = useGetBlogs({
        page,
        size: 9,
        section: id ? id : undefined,
        flag: 'L',
    });

    const isBlogUnique = (blog: Blog) => {
        return !blogs.some((existingBlog) => existingBlog.id === blog.id);
    };
    useEffect(() => {
        if (result?.result) {
            const newBlogs = result.result.filter(isBlogUnique);
            setBlogs((prevBlogs) => [...prevBlogs, ...newBlogs]);
        }
    }, [result]);
    useEffect(() => {
        setBlogs([]);
        setPage(0);
    }, [i18n.language]);
    const handleSeeMore = () => {
        setPage((prevPage) => prevPage + 1);
    };

    const isBlogsHasNext = result?.pageInfo?.hasNext

    return (
        <>
            {loading && blogs.length === 0 ? (
                <Spin
                    className='spin'
                />
            ) :
                blogs.length > 0 && (
                    <>
                        <div className="blogs-filters lastBlogs">
                            <p className="title">
                                {id ? t('BlogsAndNews.SimilarBlogs') : t('BlogsAndNews.LastBlogs')}
                            </p>
                                <Row gutter={[16, 16]}>
                                    {blogs?.map((blog: Blog) => (
                                        <Col key={blog.id} xs={24} sm={12} md={8} lg={8}>
                                            <BlogCard blog={blog} onClick={() => onBlogClick(blog.id)} />
                                        </Col>
                                    ))}
                                </Row>
                        </div>
                        {isBlogsHasNext && (
                            loading ? (
                                <Spin
                                    style={{
                                        margin: '0 auto',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        padding: screens.xs ? '0px 0px 30px 0px' : '0px 0px 77px 0px',
                                    }}
                                />
                            ) :
                                (
                                    <button
                                        onClick={handleSeeMore}
                                        style={{
                                            backgroundColor: 'transparent',
                                            fontWeight: 'var(--weight-500)',
                                            fontSize: 'var(--font-18)',
                                            color: 'var(--primary-color)',
                                            margin: '0 auto',
                                            border: 'none',
                                            borderRadius: '4px',
                                            cursor: 'pointer',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            padding: screens.xs ? '0px 0px 30px 0px' : '0px 0px 77px 0px',
                                            textDecoration: 'underline',
                                        }}
                                    >
                                        {t('BlogsAndNews.SeeMore')}
                                    </button>
                                )

                        )}
                    </>
                )
            }

        </>
    );
};

export default LastBlogs;
